@import url('https://fonts.googleapis.com/css?family=Roboto');

body, html {
  font-size: 0.9rem;
  background: #f2f3f7;
  font-family: Roboto, sans-serif;
}

body, .form-control {
  font-size: 0.9rem
}

body,
html,
#root,
.App {
  min-height: 100vh;
}

.btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.66rem;
  border-radius: 0.5rem;
  box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .05) !important
}

.App-link {
  color: #61dafb;
}

.btn-light {
  border-color: #dedede
}

.btn-light:hover {
  border-color: #dedede;
  background-color: #f3f3f3
}

.shadow {
  box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .05) !important
}

.modal-content,
.card {
  overflow: hidden;
  border-radius: 0.756rem
}

hr {
  opacity: 0.1
}

.border-radius {
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}


.fs-10px {
  font-size: 10px;
}

.fs-11px {
  font-size: 11px;
}

.w-80 {
  width: 80%;
}

.w-40 {
  width: 40%;
}

.border-left-radius {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.border-right-radius {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.toast-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10px;
  right: 10px;
}

$breathing-scale: 1.3; // Adjust this value as needed

.breathing-image {
  width: 300px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  animation: breathing 1s infinite alternate; /* Change animation duration as needed */
}

.form-control {
  border: 1px solid transparent !important;

  &.is-invalid {
    border: 1px solid var(--bs-form-invalid-border-color) !important;
  }
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale($breathing-scale);
  }
}


.table th {
  border-top: 1px solid #0000001a;
  border-bottom: 1px solid #0000001a;
  border-bottom-color: #0000001a !important
}

.table td {
  text-align: left;
}

.table tr th:first-child,
.table tr td:first-child {
  padding-left: 0
}

.table tr th:last-child,
.table tr td:last-child {
  padding-right: 0
}

.form-control {
  background: #f5f7f9;
  font-weight: 400;
  padding: .5rem .75rem;
  border: none
}

.form-control:hover {
  background: #f5f7f9;
}

.form-control::placeholder,
.form-control::-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #9fa8b0
}

.form-control:focus {
  background: #f5f7f9;
}

#invoiceCapture {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}